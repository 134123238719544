@import '/src/__variables.less';

.wrapper {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-right: 8px;
  padding: 0 8px;
  gap: 10px;
  align-items: start;
  justify-items: center;
  grid-template-columns: repeat(5, 1fr);
  overflow-y: hidden;

  @media (max-width: @max-width-lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: @max-width-md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: @max-width-sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: @max-width-xs) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  padding: 50px 20px;

  @media (max-width: @max-width-sm) {
    padding: 30px 10px;
  }
}

.ant-pagination-item-active {
  background-color: #dfdfdf !important;
  border-color: #dfdfdf !important;
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;