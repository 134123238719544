@import '/src/__variables.less';

.width250 {
  width: 250px !important;
}

.customizeWrapper {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
}

.tableRow {
  border: 1px solid var(--color-border);
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: min-content auto;

  .tableContent {
    display: flex;
    overflow: scroll;
    flex: 1 1 auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: block;
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-gray);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--color-white);
    }
  }

  .tableCol {
    min-width: 230px;
    width: fit-content;
    overflow: hidden;

    &.tableHeadCol {
      width: 210px;
    }

    .headActions {
      display: none;
      align-items: center;
      gap: 4px;
    }

    .tableHeadCell {
      position: relative;
      text-transform: uppercase;
      background: var(--color-hover-gray);
      height: 90px;
      padding: 12px;
      border: 1px solid var(--color-border);
      border-left: 0;
      margin-top: -1px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .added {
        position: absolute;
        right: 3px;
        bottom: 3px;
      }

      .headerRow {
        height: 20px;
        display: flex;
        align-items: center;
        gap: 4px;

        .title {
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex: 1 1 auto;

          &.disabled {
            cursor: default;
          }
        }
      }

      .location {
        max-width: 100%;
        cursor: default;
        font-size: 0.75rem;
        color: var(--color-gray);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      button {
        text-transform: uppercase;
        padding: 0;
      }
    }

    .tableCell {
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 4px 12px;
      border: 1px solid var(--color-border);
      border-top: 0;
      border-left: 0;
      position: relative;
      align-items: center;

      .titleBox {
        display: flex;
        gap: 4px;
        align-items: center;
        overflow: hidden;

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        .details {
          color: var(--color-gray);
          font-size: 1.25rem;
          cursor: help;
        }
      }

      .content {
        width: 100%;
        z-index: 1;
        transition: 0.2s ease-in-out;
      }

      .actions {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 4px 12px;
        align-items: center;
        justify-content: flex-end;
        display: none;
        z-index: 1000;
        background: var(--color-white);

        button {
          transition: none;
        }
      }

      &:hover {
        .actions {
          display: flex;
        }

        .withActions {
          padding-right: 32px;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &.master {
      background: var(--color-hover-gray);

      .actions {
        background: var(--color-hover-gray);
      }
    }

    &:hover {
      .headActions {
        display: flex;
      }
    }
  }
}

.icon {
  font-size: 1.25rem !important;
  color: var(--color-gray) !important;
}

.popperContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: fit-content;

  .popperText {
    font-size: 0.875rem;
    color: var(--color-gray);
  }
}

.optionRow {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0;

  svg {
    font-size: 1.25rem;
    color: var(--color-gray);
  }

  .city {
    flex: 1 1 auto;
  }

  .info {
    font-size: 0.75rem;
    color: var(--color-gray);
  }
}

&.bolded {
  font-weight: 600 !important;
  font-size: 1.25rem !important;
}

@media screen and (max-width: @max-width-xs) {
  .tableRow {
    .tableCol {
      min-width: 130px;

      &.tableHeadCol {
        width: 180px;
      }
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;