#welcomeMessage .custom-quill-editor .ql-editor {
  max-height: 150px;
  line-height: 1.1;
}

.custom-quill-editor .ql-container.ql-snow {
  border: none !important;
}

#welcomeMessage .custom-quill-editor {
  padding: 5px;
  max-height: 240px;
  overflow: auto;
}

.ql-editor .ql-size-large {
  font-size: 1.38em;
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;